var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c("a-spin", { attrs: { spinning: _vm.loading, tip: "加载中..." } }, [
        _c("div", { style: "height:" + _vm.height }, [
          _c("iframe", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: _vm.src, frameborder: "no", scrolling: "auto" },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }